import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as tf from '@tensorflow/tfjs';
import Webcam from 'react-webcam';
import './App.css';

function App() {
  const [model, setModel] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const webcamRef = useRef(null);
  const labels = ["Aku", "Apa", "Baik", "Kamu", "Nama", "Siapa"];

  useEffect(() => {
    const loadModel = async () => {
      try {
        const model = await tf.loadLayersModel('https://www.virtual-tour.psycopedia.net/model_6_6_2024/model.json');
        setModel(model);
        console.log('Model loaded successfully');
      } catch (error) {
        console.error('Error loading model:', error);
      }
    };
    loadModel();
  }, []);

  const capture = useCallback(async () => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      const video = webcamRef.current.video;
      const image = tf.browser.fromPixels(video)
        .resizeNearestNeighbor([128, 128])
        .toFloat()
        .div(tf.scalar(255.0)) // Normalize the image
        .expandDims();

      const predictions = await model.predict(image).data();
      const topPrediction = predictions.indexOf(Math.max(...predictions));
      
      setPrediction({
        class: labels[topPrediction],
        confidence: predictions[topPrediction],
      });

      // Clean up tensors
      tf.dispose(image);
    }
  }, [model]);

  const runPrediction = useCallback(() => {
    if (model) {
      capture();
      setTimeout(runPrediction, 1000); // Set interval to 1 second
    }
  }, [capture, model]);

  useEffect(() => {
    if (model) {
      runPrediction();
    }
  }, [model, runPrediction]);

  return (
    <div className="App">
      <h1>Real-Time Image Classification</h1>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={300}
      />
      {prediction && (
        <div>
          <h2>Prediction</h2>
          <p>Class: {prediction.class}</p>
          <p>Confidence: {(prediction.confidence * 100).toFixed(2)}%</p>
        </div>
      )}
    </div>
  );
}

export default App;
